import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import PageHeader from '../util/pageHeader'
// import imge from '../images/colorswatch/Kitchen/1/k1 (5).jpg'
import BedroomSec from '../components/NewCOmponents/bedroomSec'
import { tdata} from '../data/housegdata'


const TvUnit = () => (
  <Layout>
    <SEO title="TV Unit" />
    {/* <PageHeader heading="Kitchen" source="https://livedemo00.template-help.com/wt_prod-2267/images/breadcrumbs-background-01.jpg"/> */}
<BedroomSec bdata= {tdata} />
  </Layout>
)

export default TvUnit